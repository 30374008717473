/* eslint-disable @next/next/no-img-element */
"use client";

import React, { useState } from "react";
import Logo from "../ui/logo";
import Collapse from "react-bootstrap/Collapse";

function Footer() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="bg-white shadow services-footer">
        <div>
          <div className="container px-5">
            <a
              className="w-100 text-body text-decoration-none py-3 d-block"
              onClick={() => setOpen(!open)}
              role="button"
              aria-expanded={open}
              aria-controls="collapseExample"
            >
              <div className="d-flex flex-wrap justify-content-between align-items-center py-1">
                <h6 className="m-0 text-body fw-bold">Popular Services</h6>
                <span>
                  See all services <i className="bi bi-chevron-down" />{" "}
                </span>
              </div>
            </a>
          </div>
        </div>
        <Collapse
          in={open}
          className="collapse border-top"
          id="collapseExample"
        >
          <div className="container px-5 py-4">
            <div className="row">
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Cardiology
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Gynecology
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Neurology
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Orthopedics
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Pulmonology
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Gastrology
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Urology
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Laboratory
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Neurology
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Orthopedics
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Pulmonology
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Gastrology
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Urology
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Laboratory
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Cardiology
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Gynecology
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Neurology
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Orthopedics
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Pulmonology
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Gastrology
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Urology
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Laboratory
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Neurology
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Orthopedics
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Pulmonology
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Gastrology
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Urology
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-3">
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Laboratory
                </a>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="py-5 footer bg-black d-none d-xl-block">
        <div>
          <div className="container px-5 py-5">
            <div className="row">
              <div className="col-6 col-lg-4 col-md-3">
                <a
                  href="index.html"
                  className="brand d-flex align-items-center mb-3 mb-md-0 me-md-auto text-decoration-none"
                >
                  <Logo />
                </a>
                <h6 className="mb-2 text-white mt-5 fw-bold text-uppercase">
                  DOWNLOAD THE Medicil APP
                </h6>
                <p className="text-white-50">
                  Get new clients, grow your business.
                </p>
                <a className="me-2" href="#">
                  <img
                    src="img/google.png"
                    className="img-fluid app-icon"
                    alt="#"
                    loading="lazy"
                  />
                </a>
                <a href="#">
                  <img
                    src="img/apple.png"
                    className="img-fluid app-icon"
                    alt="#"
                    loading="lazy"
                  />
                </a>
              </div>
              <div className="col-6 col-lg-2 col-md-3">
                <h6 className="mb-3 text-white fw-bold">COMPANY</h6>
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="about.html"
                >
                  About
                </a>
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="blog-home.html"
                >
                  Blog
                </a>
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="services-overview.html"
                >
                  Services
                </a>
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="doctors-overview.html"
                >
                  Doctors
                </a>
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="appointment.html"
                >
                  Appointment
                </a>
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="pricing.html"
                >
                  Pricing
                </a>
              </div>
              <div className="col-6 col-lg-2 col-md-3">
                <h6 className="mb-3 text-white fw-bold">QUICK LINKS</h6>
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="signin.html"
                >
                  Login
                </a>
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="signup.html"
                >
                  Signup
                </a>
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="forgot.html"
                >
                  Forgot
                </a>
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="faq.html"
                >
                  FAQ
                </a>
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="terms-and-conditions.html"
                >
                  Terms &amp; Conditions
                </a>
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="privacy-policy.html"
                >
                  Privacy Policy{" "}
                </a>
                <a
                  className="py-1 text-decoration-none d-block w-100 text-muted"
                  href="contact.html"
                >
                  Contact{" "}
                </a>
              </div>
              <div className="col-6 col-lg-4 col-md-3">
                <h6 className="mb-3 text-white fw-bold">HELP</h6>
                <div className="d-flex gap-3 mb-3 py-1">
                  <i className="bi bi-chat-dots text-white h5 m-0" />
                  <div>
                    <p className="mb-1 fw-bold text-white">Chat with us</p>
                    <small className="text-muted">
                      Chat live with one of our support specialists.
                    </small>
                  </div>
                </div>
                <div className="d-flex gap-3 mb-3 py-1">
                  <i className="bi bi-telephone text-white h5 m-0" />
                  <div>
                    <p className="mb-1 fw-bold text-white">Call us</p>
                    <small className="text-muted">
                      Call us during normal business hours at (555) 892-9403.
                    </small>
                  </div>
                </div>
                <div className="d-flex gap-3 py-1">
                  <i className="bi bi-geo-alt text-white h5 m-0" />
                  <div>
                    <p className="mb-1 fw-bold text-white">Location</p>
                    <small className="text-muted">
                      74 Guild Street 542B, Great North Town Ludhiana.
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-dark py-4 mt-auto copyright-footer">
        <div className="container px-5">
          <div className="row align-items-center justify-content-between flex-column flex-sm-row">
            <div className="col-auto">
              <div className="small m-0 text-white">
                Copyright © Your Website 2021
              </div>
            </div>
            <div className="col-auto">
              <a className="link-light small" href="privacy-policy.html">
                Privacy
              </a>
              <span className="text-white mx-1">·</span>
              <a className="link-light small" href="terms-and-conditions.html">
                Terms
              </a>
              <span className="text-white mx-1">·</span>
              <a className="link-light small" href="contact.html">
                Contact
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
