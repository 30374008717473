"use client";

import { logoutAction } from "@/actions/auth";
import React from "react";
import { Button } from "react-bootstrap";

function LogoutButton({ className, text }) {
  return (
    <form action={logoutAction}>
      <Button
        className={className}
        type="submit"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Logout"
      >
        {text}
      </Button>
    </form>
  );
}

export default LogoutButton;
