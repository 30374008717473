"use client";

import { useState } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import Logo from "../ui/logo";
import Link from "next/link";
import LogoutButton from "../ui/LogoutButton";
import Notification from "../ui/notification";

function LandingNav({ session }) {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const nav = [
    {
      id: 1,
      title: "Home",
      href: "/",
      dropdown: false,
    },
    {
      id: 2,
      title: "Consultation",
      href: "/consultation",
      dropdown: false,
    },
    {
      id: 3,
      title: "Doctors",
      href: "/doctors",
      dropdown: false,
    },
    {
      id: 4,
      title: "About",
      href: "/about",
      dropdown: false,
    },
    { id: 5, title: "Contact", href: "/contact", dropdown: false },
  ];

  const handleToggle = () => {
    setIsNavOpen((prev) => !prev);
  };

  return (
    <Navbar
      sticky="top"
      expand="lg"
      bg="primary"
      variant="dark"
      className="osahan-nav"
    >
      <div className="container px-5">
        <Navbar.Brand>
          <Link href="/">
            <Logo />
          </Link>
        </Navbar.Brand>
        <div className="d-flex align-items-center gap-4">
          <Navbar.Toggle aria-controls="mobile-nav" onClick={handleToggle} />
          {session && <Notification nav={true} />}
        </div>
        <div
          className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
          id="mobile-nav"
        >
          <Nav className="me-auto mb-2 mb-lg-0">
            {nav.map((navItem) => (
              <Nav.Item key={navItem.id}>
                <Link href={navItem.href} className="nav-link">
                  {navItem.title}
                </Link>
              </Nav.Item>
            ))}
          </Nav>
          {session ? (
            <div className="d-flex gap-2 align-items-center justify-content-center">
              <div className="d-flex gap-4 align-items-center">
                <LogoutButton
                  className={
                    "text-decoration-none text-white text-uppercase fw-bold"
                  }
                  text={"Logout"}
                />
                <Link href="/dashboard">
                  <Button variant="warning" className="text-uppercase fw-bold">
                    Dashboard
                  </Button>
                </Link>
              </div>
              <Notification />
            </div>
          ) : (
            <div className="d-flex gap-4 align-items-center">
              <Link
                href="/login"
                className="text-decoration-none text-white text-uppercase fw-bold"
              >
                Log In
              </Link>
              <Link href="/signup">
                <Button variant="warning" className="text-uppercase fw-bold">
                  Sign up
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </Navbar>
  );
}

export default LandingNav;
