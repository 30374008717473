import Image from "next/image";
import React from "react";

function Logo({ width = 139.188, height = 30 }) {
  return (
    <Image
      className="img-fluid"
      src="/img/logo.png"
      alt="Your Logo"
      width={width}
      height={height}
    />
  );
}

export default Logo;
