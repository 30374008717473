"use client";

import { useEffect, useState, useMemo, useCallback } from "react";
import { Offcanvas, Button } from "react-bootstrap";
import { useNotifications } from "../notification/notificationContext";
import { formatDistanceToNow } from "date-fns";
import { toast } from "sonner";
import "../../styles/notifications.css";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { FaExternalLinkAlt } from "react-icons/fa";

const notificationSound =
  typeof window !== "undefined" ? new Audio("/ping.mp3") : null;

function Notification({ nav }) {
  const { notifications } = useNotifications();
  const [show, setShow] = useState(false);
  const pathname = usePathname();

  const notificationsArray = useMemo(
    () => notifications || [],
    [notifications]
  );

  useEffect(() => {
    const meetingPathRegex = /^\/appointments\/\d+\/meeting$/;

    // notifications when not on the meeting page
    if (!meetingPathRegex.test(pathname) && notificationsArray.length > 0) {
      const shownNotifications = new Set();

      notificationsArray.forEach((notification) => {
        const { event_type, payload } = notification;
        const { message_id, header } = payload;

        if (event_type === "ping" && !shownNotifications.has(message_id)) {
          toast.info(header, {
            position: "top-center",
            duration: Infinity,
          });

          if (notificationSound) {
            notificationSound.play().catch((error) => {
              console.error("Error playing notification sound:", error);
            });
          }

          shownNotifications.add(message_id);
        }
      });
    }
  }, [pathname, notificationsArray]);

  const handleShow = useCallback(() => setShow(true), []);
  const handleClose = useCallback(() => setShow(false), []);

  return (
    <>
      <div
        className={`position-relative ${nav ? "hide-lg" : "hide-sm"}`}
        style={{
          height: !nav ? "65px" : undefined,
          padding: !nav ? "0 1.5rem" : undefined,
        }}
      >
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            height: "40px",
            width: "40px",
            background: "white",
            borderRadius: "50%",
            border: "1px solid rgba(0, 0, 0, 0.15)",
            cursor: "pointer",
            position: "relative",
          }}
          onClick={handleShow}
        >
          <i
            className="bi bi-bell-fill"
            style={{ fontSize: "1rem", color: "#212529" }}
          ></i>
          {notificationsArray.length > 0 && (
            <div className="notification-badge">
              {notificationsArray.length}
            </div>
          )}
        </div>
      </div>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Notifications</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {notificationsArray.length > 0 ? (
            <>
              <div className="d-flex gap-2 mb-3">
                <Button variant="light" size="sm">
                  Mark all as Read
                </Button>
                <Button variant="light" size="sm">
                  Clear All
                </Button>
              </div>
              {notificationsArray.map((notification) => (
                <div
                  key={notification.created_at}
                  className="dropdown-item-custom mb-3"
                >
                  <Link
                    className={
                      notification.redirect_to ? "custom-underline-hover" : ""
                    }
                    target="_blank"
                    href={notification.redirect_to || ""}
                  >
                    <p>
                      <strong>{notification.payload.header}</strong>
                      {notification.redirect_to && (
                        <span className="mx-2">
                          <FaExternalLinkAlt size={12} />
                        </span>
                      )}
                    </p>
                  </Link>
                  <p>{notification.payload.body}</p>
                  <small className="text-muted">
                    {formatDistanceToNow(new Date(notification.created_at), {
                      addSuffix: true,
                    })}
                  </small>
                </div>
              ))}
            </>
          ) : (
            <p>No notifications</p>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Notification;
