"use client";

import { createContext, useRef, useContext, useEffect, useState } from "react";
import Pusher from "pusher-js";
import { getSession } from "@/actions/auth";
import { fetchNotifications } from "@/actions/notification";

const NotificationContext = createContext();

export const useNotifications = () => {
  return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
  const pusherInstanceRef = useRef(null);
  const channelRef = useRef(null);
  const isInitilizing = useRef(false);
  const [notifications, setNotifications] = useState([]);

  const initializePusher = async () => {
    if (isInitilizing.current) {
      return;
    }
    isInitilizing.current = true;

    const user = await getSession();

    if (!user) {
      return;
    }

    const pusherObject = new Pusher(`${process.env.NEXT_PUBLIC_PUSHER_KEY}`, {
      cluster: `${process.env.NEXT_PUBLIC_PUSHER_CLUSTER}`,
      channelAuthorization: {
        endpoint: `/notifications/pusher-auth`,
      },
    });

    pusherInstanceRef.current = pusherObject;

    const channelInstance = pusherObject.subscribe(
      `private-user-${user.id}-channel`
    );
    channelRef.current = channelInstance;

    channelInstance.bind("notification", (data) => {
      setNotifications((prevNotifications) => {
        return [data, ...prevNotifications];
      });
    });

    isInitilizing.current = false;
  };

  useEffect(() => {
    const getNotifications = async () => {
      const fetchedNotifications = await fetchNotifications();
      setNotifications(fetchedNotifications?.items);
    };
    getNotifications();
    initializePusher();

    return () => {
      if (channelRef.current) {
        channelRef.current.unbind_all();
        channelRef.current.unsubscribe();
      }
      if (pusherInstanceRef.current) {
        pusherInstanceRef.current.disconnect();
      }
    };
  }, []);

  return (
    <NotificationContext.Provider value={{ notifications }}>
      {children}
    </NotificationContext.Provider>
  );
};
